import { Auth } from 'aws-amplify';

const configVariables = {
    termsAndConditionsUrl: 'https://www.smile-academy.com.au/terms-and-conditions',
    contactEmailAddress: 'info@smile-academy.com.au',
    youtubeVideo: 'https://www.youtube.com/watch?v=kwcwJ1_WcFk&ab_channel=SmileAcademy',
    consentFormUrl: 'https://bit.ly/3iItUHf',
    marketingMaterialUrl:
        'https://smileacademycomau-my.sharepoint.com/:f:/g/personal/aurelien_smile-academy_com_au/Eh2quvnpaK9Coy_HDU4ivk0BaclXV7AX93kW2IOpSCXrVA?e=lpx8y6',
    pricingUrl:
        'https://smileacademycomau-my.sharepoint.com/:b:/g/personal/aurelien_smile-academy_com_au/ERwIFQNqBY5AiCqmAd-YqiQBGAOMusGhswNkPxpzgt0PIw?e=OZh3V9',
    // if isBackend is false, data will be mocked on the frontend. If true, if will use this config file to fetch data
    isBackend: process.env.REACT_APP_BACKEND === 'true',
    Auth: {
        email: 'admin@smile-academy.com.au', // used for local sign in only
        password: 'password',
        mandatorySignIn: true,
        region: 'ap-southeast-2',
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: 'dentist-portal-api',
                endpoint: process.env.REACT_APP_API_GATEWAY_URL,
                region: 'ap-southeast-2',
                custom_header: async () => {
                    try {
                        const session = await Auth.currentSession();
                        return { Authorization: session.idToken.jwtToken };
                    } catch (error) {
                        return;
                    }
                },
            },
        ],
    },
};

export default configVariables;
