import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { Grid, Paper, TextField } from '@material-ui/core'

// styles
import useStyles from './styles'

//components
import { Button, Typography } from '../../components/Wrappers'

// logo
import logo from '../../images/logo-large.jpg'

// context
import { actions } from '../../context/OrderContext'

const TreatmentPlan = () => {
    const classes = useStyles()
    const location = useLocation()

    const [practiceId, setPracticeId] = useState()
    const [orderId, setOrderId] = useState()
    const [token, setToken] = useState('')

    useEffect(() => {
        const queryString = new URLSearchParams(location.search)
        setPracticeId(queryString.get('practiceId'))
        setOrderId(queryString.get('orderId'))
    }, [location.search]) //eslint-disable-line

    const handleChange = e => setToken(e.target.value)

    const handleDownloadTreatmentPlan = () => {
        if (!practiceId || !orderId) {
            toast.error('The URL to acess the treatment plan is invalid')
        } else {
            actions.doDownloadTreatmentFile(practiceId, orderId, token)
        }
    }

    return (
        <Grid container className={classes.container}>
            <Paper classes={{ root: classes.paperRoot }}>
                <div className={classes.logotype}>
                    <img className={classes.logotypeIcon} src={logo} alt="logo" />
                </div>
                <Typography variant="h5" color="primary" className={classes.textRow}>
                    Please, enter the token to access your treatment plan
                </Typography>
                <TextField
                    id="token"
                    onChange={handleChange}
                    value={token}
                    type="text"
                    variant="outlined"
                    placeholder="Token"
                    className={classes.textField}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    onClick={handleDownloadTreatmentPlan}
                    disabled={!token.length}
                >
                    Download
                </Button>
            </Paper>
        </Grid>
    )
}

export default TreatmentPlan
