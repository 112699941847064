import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Grid, Button, IconButton, Tooltip, FormHelperText } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { sentenceCase } from 'sentence-case';
import { colorForStatus, orderStatuses } from './helpers/statuses';

// styles
import useStyles from './styles';

// components
import { Typography, Chip } from '../../components/Wrappers/Wrappers';
import Widget from '../../components/Widget/Widget';

// context
import { useOrderDispatch, useOrderState, actions } from '../../context/OrderContext';
import { useUserState } from '../../context/UserContext';

// helpers
import { scanMethod, excludedProperties } from './helpers/form';

const ViewOrder = () => {
    const classes = useStyles();

    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();
    const userState = useUserState();

    const history = useHistory();
    const location = useLocation();

    const entries = Object.entries(orderState?.currentOrder || {});

    // get the practiceId from the current user
    const practiceId = userState.currentUser?.practice?.practiceId;

    useEffect(() => {
        const orderId = new URLSearchParams(location.search).get('orderId');
        if (!orderId) {
            toast.error('Unable to find the order id');
            setTimeout(() => backToList(), 2000);
        } else if (!orderState.currentOrder || orderState.currentOrder.orderId !== orderId) {
            actions.doFind(orderId)(orderDispatch);
        }
    }, [location.search]); //eslint-disable-line

    useEffect(() => {
        if (!orderState.findError) return;
        setTimeout(() => backToList(), 2000);
    }, [orderState?.findError]); //eslint-disable-line

    const handleCancelOrder = () => {
        const { orderId } = orderState.currentOrder;
        actions.doCancel(orderId, practiceId)(orderDispatch, history);
    };

    const handleClickCreateRefinement = () => {
        const { orderId } = orderState.currentOrder;
        history.push(`refine?practiceId=${practiceId}&orderId=${orderId}`);
    };

    const handleClickViewRefinement = () => {
        const { refinementOrderId } = orderState.currentOrder;
        history.push(`view?practiceId=${practiceId}&orderId=${refinementOrderId}`);
    };

    const handleOpenLink = (url) => (_) => window.open(url, '_blank');

    const handleCopyToken = (token) => (_) => navigator.clipboard.writeText(token);

    const backToList = () => history.goBack();

    const renderEntry =
        (order) =>
        ([key, value]) => {
            if (excludedProperties.includes(key)) return null;
            if (!value || !value.length) return null;
            if (key.match('orderId|previousOrderId|refinementOrderId')) return null;
            if (key === 'links') return value.map(renderCaseLink);

            const orderStatus = order?.orderStatus;

            // order cancellation and refinement
            const canCancelOrder = orderStatus === orderStatuses.CASE_NEEDS_APPROVAL;
            const canCreateRefinement = orderStatus === orderStatuses.CASE_COMPLETE && !order?.refinementOrderId;
            const orderHasRefinement = orderStatus === orderStatuses.CASE_COMPLETE && order?.refinementOrderId;

            return (
                <Grid item container key={key}>
                    <Grid item xs={6}>
                        <Typography variant="body1" weight="bold">
                            {key.match('IPR') ? sentenceCase(key).replace('ipr', 'IPR') : sentenceCase(key)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {key === 'orderStatus' ? (
                            <>
                                <Chip label={value} color={colorForStatus(value)} />
                                {orderState.currentOrder?.orderStatus === orderStatuses.CASE_NEEDS_APPROVAL &&
                                    orderState.currentOrder?.treatmentInvoiced && (
                                        <Tooltip
                                            title={
                                                <Typography fontSize={30}>
                                                    Case pending for over 2 months. Treatment plan only has been
                                                    invoiced and will be deducted from total course cost when case is
                                                    approved.
                                                </Typography>
                                            }
                                        >
                                            <IconButton>
                                                <WarningIcon color="secondary" />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                {canCancelOrder && (
                                    <>
                                        <Button
                                            onClick={handleCancelOrder}
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: 15 }}
                                        >
                                            Cancel Order
                                        </Button>
                                    </>
                                )}

                                {canCreateRefinement && (
                                    <Button
                                        onClick={handleClickCreateRefinement}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginLeft: 15 }}
                                    >
                                        Create Refinement
                                    </Button>
                                )}

                                {orderHasRefinement && (
                                    <Button
                                        onClick={handleClickViewRefinement}
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginLeft: 15 }}
                                    >
                                        View Refinement
                                    </Button>
                                )}
                            </>
                        ) : key === 'method' ? (
                            <Typography className={classes.text}>
                                {value === scanMethod ? '3D Scan' : 'Traditional Impression'}
                            </Typography>
                        ) : key === 'instructions' ? (
                            value.split('\n').map((item, idx) => (
                                <span key={idx}>
                                    <Typography className={classes.text}>{item}</Typography>
                                </span>
                            ))
                        ) : (
                            <Typography className={classes.text}>{value}</Typography>
                        )}
                    </Grid>
                </Grid>
            );
        };

    const renderCaseLink = ({ url, type, token }) => {
        const isApprovalLink = type === 'approval';
        return (
            <Grid item container alignItems="center" key={`link-${type}`}>
                <Grid item xs={6}>
                    {isApprovalLink ? (
                        <>
                            <Typography variant="body1" weight="bold">
                                Dentist link
                            </Typography>
                            <FormHelperText>(for case approval)</FormHelperText>
                        </>
                    ) : (
                        <>
                            <Typography variant="body1" weight="bold">
                                Patient link
                            </Typography>
                            <FormHelperText>(forward to patient - 3D viewer only)</FormHelperText>
                        </>
                    )}
                </Grid>
                <Grid item container xs={6} alignItems="center">
                    {isApprovalLink && (
                        <Tooltip
                            placement="top"
                            color="secondary"
                            disableFocusListener
                            disableTouchListener
                            title="Copy to access the case details"
                            enterDelay={500}
                        >
                            <Button
                                className={classes.copyTokenButton}
                                color="primary"
                                variant="contained"
                                onClick={handleCopyToken(token)}
                            >
                                Click to copy token
                            </Button>
                        </Tooltip>
                    )}
                    <Button
                        className={classes.openLinkButton}
                        color="secondary"
                        variant="contained"
                        onClick={handleOpenLink(url)}
                    >
                        Open 3D viewer
                    </Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={720}>
                            <Typography variant="h5" weight="medium" style={{ marginBottom: 30 }}>
                                Order details
                            </Typography>
                            <Grid container direction="column" spacing={3}>
                                {orderState?.findLoading ? (
                                    <CircularProgress color="secondary" className={classes.progressCentered} />
                                ) : (
                                    entries.map(renderEntry(orderState?.currentOrder))
                                )}
                            </Grid>
                            <Box display="flex" justifyContent="center" style={{ marginTop: 50 }}>
                                <Button onClick={backToList} variant="outlined" color="secondary">
                                    Back
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    );
};

export default ViewOrder;
