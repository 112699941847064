import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Chip as ChipBase,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
} from '@material-ui/core';

import { Search as SearchIcon, Warning as WarningIcon } from '@material-ui/icons';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import Widget from '../../components/Widget';
import { Chip, Typography, Avatar } from '../../components/Wrappers';
import PropTypes from 'prop-types';

// context
import { useOrderDispatch, useOrderState, actions as orderActions } from '../../context/OrderContext';

// helpers
import { stableSort, getSorting } from '../../common/helpers';
import { colorForStatus, orderStatuses } from './helpers/statuses';

/**
 * Collect the N to N details from the treatment type
 * @param {*} treatmentType: Treatment From Canine to Canine (3 to 3)
 * @returns 3 to 3
 */
const getShortTreatmentType = (treatmentType) => treatmentType.match(/\d to \d/);

/**
 * Returns orders matching the search input
 */
const filterItems = (orders, input) => {
    var regexp = new RegExp(input, 'gi');
    return orders.filter((o) => {
        const treatmentType = getShortTreatmentType(o.treatmentType);
        return (
            (treatmentType && treatmentType[0]?.match(regexp)) ||
            o.patientName?.match(regexp) ||
            o.prescriberName?.match(regexp) ||
            o.caseNumber?.match(regexp) ||
            o.serialNumber?.match(regexp) ||
            o.orderStatus?.match(regexp)
        );
    });
};

const headCells = [
    { id: 'prescriberName', alignLeft: true, disablePadding: false, label: 'Prescriber', isSortable: true },
    { id: 'patientName', alignLeft: true, disablePadding: false, label: 'Patient', isSortable: true },
    { id: 'treatmentType', alignLeft: true, disablePadding: false, label: 'treatment type', isSortable: true },
    { id: 'createdAt', alignLeft: true, disablePadding: false, label: 'Date of creation', isSortable: true },
    { id: 'caseNumber', alignLeft: true, disablePadding: false, label: 'Case number', isSortable: false },
    { id: 'status', alignLeft: true, disablePadding: false, label: 'Status', isSortable: false },
    { id: 'refinement', alignLeft: true, disablePadding: false, label: 'Refinement Case', isSortable: false },
];

const EmptyTableRow = (isLoading) => (
    <TableRow key="no-data-row" style={{ height: 150 }}>
        <TableCell colSpan={headCells.length} align="center" style={{ textAlign: 'center' }}>
            {isLoading ? (
                <CircularProgress color="secondary" />
            ) : (
                <Typography variant="h6" color="primary">
                    No order found
                </Typography>
            )}
        </TableCell>
    </TableRow>
);

const EnhancedTableToolbar = ({ classes, onSearchInputChange, onOrderStatusChange, searchValue }) => (
    <Toolbar style={{ marginTop: 10 }}>
        <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ display: 'flex', gap: 8 }}>
                <ChipBase
                    label="Cases in progress"
                    onClick={onOrderStatusChange('in-progress')}
                    className={classNames(classes.orderStatusChip, {
                        active: searchValue.orderStatus === 'in-progress',
                    })}
                />
                <ChipBase
                    label="Cases completed"
                    onClick={onOrderStatusChange('complete')}
                    className={classNames(classes.orderStatusChip, { active: searchValue.orderStatus === 'complete' })}
                />
            </Grid>
            <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                <IconButton>
                    <SearchIcon />
                </IconButton>
                <TextField
                    color="primary"
                    type="search"
                    placeholder="Search"
                    fullWidth
                    onChange={onSearchInputChange}
                    value={searchValue.searchInput || ''}
                />
            </Grid>
        </Grid>
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    onSearchInputChange: PropTypes.func.isRequired,
    onOrderStatusChange: PropTypes.func.isRequired,
    searchValue: PropTypes.object.isRequired,
};

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignLeft ? 'left' : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.isSortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={order}
                                onClick={createSortHandler(headCell.id)}
                                style={{
                                    whiteSpace: 'nowrap',
                                    textTransform: 'uppercase',
                                    fontSize: '0.85rem',
                                }}
                            >
                                <Typography uppercase color="text" variant={'body2'} colorBrightness="hint">
                                    {headCell.label}
                                </Typography>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <Typography uppercase color="text" variant={'body2'} colorBrightness="hint">
                                {headCell.label}
                            </Typography>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const OrderList = () => {
    const classes = useStyles();
    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();
    const history = useHistory();
    const location = useLocation();

    // Orders table
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    // Search feature
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (!orderState.orders) orderActions.doFetch()(orderDispatch);
    }, []); // eslint-disable-line

    /**
     * Check the query string to set the order status filter
     */
    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const orderStatus = queryString.get('orderStatus');
        const currentStatusFilter = orderState.search.orderStatus;
        if (orderStatus?.match('in-progress|complete') && orderStatus !== currentStatusFilter) {
            // the orderStatus filter must be updated
            orderActions.doResetState()(orderDispatch);
            const search = { searchInput: '', orderStatus };
            orderActions.doSearchOrders(search)(orderDispatch);
        }
    }, [location.search]); //eslint-disable-line

    useEffect(() => {
        setFilteredItems(orderState.orders?.items || []);
    }, [orderState.orders]); // eslint-disable-line

    /**
     * Fetch orders when order status changes
     * The state is reset on status change so only fetch when no order is found
     */
    useEffect(() => {
        if (orderState.search.orderStatus && !orderState.orders) {
            orderActions.doFetch({ orderStatus: orderState.search.orderStatus })(orderDispatch);
        }
    }, [orderState.search.orderStatus]); // eslint-disable-line

    useEffect(() => {
        const input = orderState.search.searchInput || '';
        const orders = orderState.orders?.items || [];
        if (input.length === 0) {
            setFilteredItems(orders);
        } else if (input.length > 2) {
            setFilteredItems(filterItems(orders, input));
        }
    }, [orderState.search.searchInput]); // eslint-disable-line

    // =================== Handlers ======================

    const handleRequestSort = (_, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInputChange = (e) => {
        const search = { ...orderState.search, searchInput: e.target.value };
        orderActions.doSearchOrders(search)(orderDispatch);
    };

    const handleOrderStatusFilter = (newStatus) => () => {
        orderActions.doResetState()(orderDispatch);
        const search = { searchInput: '', orderStatus: newStatus };
        orderActions.doSearchOrders(search)(orderDispatch);
        // update the query string in the url according to the orderStatus filter value
        history.replace(`list?orderStatus=${newStatus}`);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget noBodyPadding bodyClass={classes.tableWidget}>
                    <EnhancedTableToolbar
                        classes={classes}
                        onSearchInputChange={handleSearchInputChange}
                        onOrderStatusChange={handleOrderStatusFilter}
                        searchValue={orderState.search}
                    />

                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="recent orders">
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={filteredItems.length}
                            />
                            <TableBody>
                                {(orderState.fetchLoading || filteredItems?.length === 0) &&
                                    EmptyTableRow(orderState.fetchLoading)}
                                {!orderState.fetchLoading &&
                                    filteredItems?.length > 0 &&
                                    stableSort(filteredItems, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.orderId}
                                                onClick={() => history.push(`view?orderId=${row.orderId}`)}
                                            >
                                                <TableCell>{row.prescriberName}</TableCell>
                                                <TableCell>
                                                    <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
                                                        {row.patientName && (
                                                            <Avatar
                                                                alt={row.patientName}
                                                                color={index % 2 ? 'tertiary' : 'quaternary'}
                                                                style={{ marginRight: 8 }}
                                                            >
                                                                {row.patientName.split(' ').map((name) => name[0])}
                                                            </Avatar>
                                                        )}
                                                        <Typography style={{ whiteSpace: 'nowrap' }}>
                                                            {row.patientName}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{getShortTreatmentType(row.treatmentType)}</TableCell>
                                                <TableCell>
                                                    {row.createdAt ? moment(row.createdAt).format('DD/MM/YY') : ''}
                                                </TableCell>
                                                <TableCell>{row.caseNumber || row.serialNumber}</TableCell>
                                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                    <Chip
                                                        label={row.orderStatus || 'Unknown'}
                                                        color={colorForStatus(row.orderStatus)}
                                                    />
                                                    {row.orderStatus === orderStatuses.CASE_NEEDS_APPROVAL &&
                                                        row.treatmentInvoiced && (
                                                            <Tooltip
                                                                title={
                                                                    <Typography fontSize={30}>
                                                                        Case pending for over 2 months. Treatment plan
                                                                        only has been invoiced and will be deducted from
                                                                        total course cost when case is approved.
                                                                    </Typography>
                                                                }
                                                            >
                                                                <IconButton>
                                                                    <WarningIcon color="secondary" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.refinement === 'Yes' ? 'Yes' : ''}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={filteredItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Widget>
            </Grid>
        </Grid>
    );
};

export default OrderList;
