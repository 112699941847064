import { Card, CardActions, CardContent, CardHeader, CardMedia, Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

// styles
import useStyles from './styles';

// components
import { Button, Typography } from '../../components/Wrappers';

import newOrderImage from '../../images/new-order-dark.jpg';
import viewOrderImage from '../../images/view-order-dark.jpg';

const Dashboard = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleViewOrder = () => history.push('/app/order/list?orderStatus=in-progress');

    const handleCreateOrder = () => history.push('/app/order/add');

    const CreateOrderCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="Create a New Order" />
            <CardMedia className={classes.media} image={newOrderImage} title="Create New Order" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Upload the 3D scan files of a patient and submit a new order to request a treatment
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" onClick={handleCreateOrder}>
                    Create
                </Button>
            </CardActions>
        </Card>
    );

    const ViewOrdersCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="View My Orders" />
            <CardMedia className={classes.media} image={viewOrderImage} title="View Orders" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    View your orders, check their status, access dentist links to review and approve cases as well as
                    patient links
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" onClick={handleViewOrder}>
                    View
                </Button>
            </CardActions>
        </Card>
    );

    const CreateRefinementCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="Create Refinement" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    View a list of the complete orders and select the one one you want to refine
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={() => history.push('/app/order/list?orderStatus=complete')}
                >
                    Create
                </Button>
            </CardActions>
        </Card>
    );

    const EmptyCard = () => <Card className={classes.cardMediaHidden} />;

    return (
        <>
            <Grid justifyContent="center" container>
                <CreateOrderCard />
                <ViewOrdersCard />
            </Grid>

            <Grid justifyContent="center" container>
                <CreateRefinementCard />
                <EmptyCard />
            </Grid>
        </>
    );
};

export default Dashboard;
