import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
import { Typography } from '../../components/Wrappers/Wrappers';
import Widget from '../../components/Widget/Widget';
import {
    Breadcrumb,
    FormFooter,
    TreatmentPlan,
    CaseAttributes,
    CaseDesign,
    CaseMethod,
    FileAttachment,
    OrderConfirmation,
    InvoicingDelivery,
} from './components';

// context
import { useOrderDispatch, useOrderState, actions as orderActions } from '../../context/OrderContext';
import { useUserState } from '../../context/UserContext';

// form helper
import {
    emptyOrder,
    invoicingRecipientAttributes,
    caseDesignAttributes,
    deliveryAddressAttributes,
    findMissingRequiredFields,
    formatInvoicingAndDelivery,
    defaultFormStepsTitle,
    hasMissingRequiredFields,
    sanitizeFormEntry,
    scanMethod,
} from './helpers/form';

import { otherOption } from './components/InvoicingDelivery';

const AddOrder = () => {
    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();
    const userState = useUserState();
    const history = useHistory();

    const [newOrder, setNewOrder] = useState(emptyOrder());
    const [activeStep, setActiveStep] = useState(userState.isOrtho ? 0 : 1);
    const [acceptTC, setAcceptTC] = useState(false);
    const [error, setError] = useState({});

    const provideTreatmentPlan = newOrder.provideTreatmentPlan === 'Yes';
    const allowFullArchTreatment = userState.currentUser?.practice?.allowFullArchTreatment === 'Yes' ?? false;

    // ===== Hooks  =====

    useEffect(() => {
        orderActions.doNewForm()(orderDispatch);
        if (!userState.isOrtho) setNewOrder({ ...newOrder, provideTreatmentPlan: 'No' });
    }, []); // eslint-disable-line

    // ===== Navigation  =====

    const goNext = (step = 1) => setActiveStep((prevActiveStep) => prevActiveStep + step);

    const goBack = (step = 1) => setActiveStep((prevActiveStep) => prevActiveStep - step);

    const checkTreatmentPlan = () => {
        if (newOrder.provideTreatmentPlan === '') {
            toast.error('Please select an option');
        } else {
            goNext();
        }
    };

    const checkCaseAttributes = () => {
        const isValidPrescriberName = newOrder.prescriberName.trim().length;
        const isValidPatientName = newOrder.patientName.trim().length;

        if (isValidPrescriberName && isValidPatientName) return goNext();
        setError({ ...error, prescriberName: !isValidPrescriberName, patientName: !isValidPatientName });
    };

    const checkCaseDesign = () => {
        const caseDesignForm = caseDesignAttributes(newOrder, allowFullArchTreatment);
        const requiredFieldErrors = findMissingRequiredFields(caseDesignForm, newOrder);
        setError({ ...error, ...requiredFieldErrors });
        if (!hasMissingRequiredFields(requiredFieldErrors)) provideTreatmentPlan ? goNext(2) : goNext();
    };

    const checkMethod = () => {
        if (!newOrder.method) {
            toast.error('Please select an option');
        } else {
            goNext();
        }
    };

    const checkFileAttachment = () => {
        if (!orderState.fileUpload) {
            toast.error("Please upload the patient's files");
        } else {
            goNext();
        }
    };

    const checkInvoicingAndDelivery = () => {
        let isValidInvoicing = true;
        if (newOrder.invoicingOption === otherOption) {
            const requiredFieldErrors = findMissingRequiredFields(invoicingRecipientAttributes, newOrder);
            isValidInvoicing = !hasMissingRequiredFields(requiredFieldErrors);
            setError({ ...error, ...requiredFieldErrors });
        }
        if (!isValidInvoicing) return;

        let isValidDelivery = true;
        if (newOrder.deliveryOption === otherOption) {
            const requiredFieldErrors = findMissingRequiredFields(deliveryAddressAttributes, newOrder);
            isValidDelivery = !hasMissingRequiredFields(requiredFieldErrors);
            setError({ ...error, ...requiredFieldErrors });
        }
        if (!isValidDelivery) return;

        if (!acceptTC) {
            toast.error('You must accept the Terms & Conditions');
        } else {
            doSubmit(newOrder, history);
            goNext();
        }
    };

    const checkOrderConfirmation = () => history.push('/app/dashboard');

    const formStepsValidation = [
        checkTreatmentPlan,
        checkCaseAttributes,
        checkCaseDesign,
        checkMethod,
        checkFileAttachment,
        checkInvoicingAndDelivery,
        checkOrderConfirmation,
    ];

    // ===== Handlers  =====

    const handleTextFieldChange = (e) => {
        if (e.target.required) setError({ ...error, [e.target.name]: !e.target.value });
        setNewOrder({ ...newOrder, [e.target.name]: e.target.value });
    };

    /**
     * Use emptyOrder to reset the potential properties set before. We do not want to save irrelevant properties
     * Also set the method to Scan when treatment is provided
     */
    const handleNeedTreatmentPlan = (provideTreatmentPlan) => (_) => {
        setNewOrder({ ...emptyOrder(), provideTreatmentPlan, method: provideTreatmentPlan ? scanMethod : '' });
    };

    const handleMethodClicked = (method) => (_) => setNewOrder({ ...newOrder, method });

    const handleNext = async () => formStepsValidation[activeStep]();

    const handleBack = () => {
        const avoidMethodStep = provideTreatmentPlan && activeStep === 4;
        avoidMethodStep ? goBack(2) : goBack();
    };

    const doSubmit = async (formValues) => {
        const formattedForm = formatInvoicingAndDelivery(formValues, userState.currentUser);
        const sanitizedFormValues = Object.entries(formattedForm).reduce(sanitizeFormEntry, {});
        const territory = userState.currentUser?.practice?.territory;
        const data = { ...sanitizedFormValues, fileUpload: orderState.fileUpload, territory };
        await orderActions.doCreate(data)(orderDispatch);
    };

    const formStepsComponent = [
        <TreatmentPlan newOrder={newOrder} needTreatmentPlanClicked={handleNeedTreatmentPlan} />,
        <CaseAttributes newOrder={newOrder} formErrors={error} onTextFieldChange={handleTextFieldChange} />,
        <CaseDesign
            newOrder={newOrder}
            allowFullArchTreatment={allowFullArchTreatment}
            formErrors={error}
            onTextFieldChange={handleTextFieldChange}
        />,
        <CaseMethod newOrder={newOrder} methodClicked={handleMethodClicked} />,
        <FileAttachment newOrder={newOrder} />,
        <InvoicingDelivery
            user={userState.currentUser}
            newOrder={newOrder}
            formErrors={error}
            onTextFieldChange={handleTextFieldChange}
            termsAccepted={acceptTC}
            termsClicked={(clicked) => setAcceptTC(clicked)}
        />,
        <OrderConfirmation newOrder={newOrder} />,
    ];

    return (
        <Grid container spacing={3}>
            <Breadcrumb activeStep={activeStep} stepsTitle={defaultFormStepsTitle} />
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={600}>
                            <Typography variant="h5" weight="medium" style={{ marginBottom: 30 }}>
                                {defaultFormStepsTitle[activeStep]}
                            </Typography>
                            {formStepsComponent[activeStep]}
                            <FormFooter
                                activeStep={activeStep}
                                isRefinement={false}
                                onClickNext={handleNext}
                                onClickBack={handleBack}
                            />
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    );
};

export default AddOrder;
