import { Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import React from 'react';

// config
import config from '../../config';

// styles
import useStyles from './styles';

// components
import { Button, Typography } from '../../components/Wrappers';

const Download = () => {
    const classes = useStyles();

    const ViewPricingCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="View Pricing" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Click here to download Smile Academy updated pricing for our clear aligners, retainers and night
                    guards
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" href={config.pricingUrl} target="_blank">
                    Open
                </Button>
            </CardActions>
        </Card>
    );

    const DownloadConsentCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="Download Consent Form" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    We provide this document so you can inform your patients of potential risks associated with clear
                    aligner therapy, and set their expectations appropriately. We encourage you to review the
                    information on this form with your patients and sign it together
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" href={config.consentFormUrl} target="_blank">
                    Open
                </Button>
            </CardActions>
        </Card>
    );

    const DownloadMarketingCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="Download Marketing Materials" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Feel free to use any of Smile Academy content available on the following link. You will find
                    documents such as photos and videos for your social media platforms (Facebook, Instagram, Youtube…)
                    as well as posters, leaflets and more
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" href={config.marketingMaterialUrl} target="_blank">
                    Open
                </Button>
            </CardActions>
        </Card>
    );

    const EmptyCard = () => <Card className={classes.cardMediaHidden} />;

    return (
        <>
            <Grid justifyContent="center" container>
                <ViewPricingCard />
                <DownloadConsentCard />
            </Grid>

            <Grid justifyContent="center" container>
                <DownloadMarketingCard />
                <EmptyCard />
            </Grid>
        </>
    );
};

export default Download;
