import React from 'react';
import { toast } from 'react-toastify';
import { API } from 'aws-amplify';
import axios from 'axios';
import config from '../config';
import { mockOrder, mockFileUpload, mockOrdersList } from './mock';
import { getUniqueFilename, extractExtensionFrom } from '../common/helpers';
import { impressionMethod, scanMethod } from '../pages/order/helpers/form';

var OrderStateContext = React.createContext();
var OrderDispatchContext = React.createContext();

const apiName = config.API.endpoints[0].name;

const initialData = {
    findLoading: false,
    findError: false,
    fetchLoading: false,
    fetchError: false,
    saveLoading: false,
    saveError: false,
    currentOrder: null,
    fileUploadLoading: false,
    fileUploadError: false,
    fileUpload: null,
    orders: null,
    search: {
        searchInput: '',
        orderStatus: '',
    },
};

function orderReducer(state = initialData, { type, payload }) {
    switch (type) {
        case 'ORDER_RESET_STATE':
            return { ...initialData };
        case 'ORDER_FORM_RESET':
            return { ...state, saveLoading: false, saveError: false, fileUpload: null };
        case 'ORDER_FORM_FIND_STARTED':
            return { ...state, currentOrder: null, findLoading: true, findError: false };
        case 'ORDER_FORM_FIND_SUCCESS':
            return { ...state, currentOrder: payload, findLoading: false };
        case 'ORDER_FORM_FIND_ERROR':
            return { ...state, currentOrder: null, findLoading: false, findError: true };
        case 'ORDER_FORM_SAVE_STARTED':
            return { ...state, saveLoading: true, saveError: false };
        case 'ORDER_FORM_SAVE_SUCCESS':
            return { ...state, saveLoading: false, currentOrder: null, orders: null };
        case 'ORDER_FORM_SAVE_ERROR':
            return { ...state, saveLoading: false, saveError: true };
        case 'ORDER_LIST_FETCH_STARTED':
            return { ...state, fetchLoading: true, fetchError: false };
        case 'ORDER_LIST_FETCH_SUCCESS':
            return { ...state, orders: payload, fetchLoading: false };
        case 'ORDER_LIST_FETCH_ERROR':
            return { ...state, orders: null, fetchLoading: false, fetchError: true };
        case 'ORDER_FORM_FILE_UPLOAD_STARTED':
            return { ...state, fileUploadLoading: true, fileUploadError: false, fileUpload: null };
        case 'ORDER_FORM_FILE_UPLOAD_SUCCESS':
            return { ...state, fileUploadLoading: false, fileUpload: payload };
        case 'ORDER_FORM_FILE_UPLOAD_ERROR':
            return { ...state, fileUploadLoading: false, fileUploadError: true };
        case 'ORDER_FORM_FILE_UPLOAD_DELETE':
            return { ...state, fileUpload: null };
        case 'ORDER_LIST_SEARCH':
            return { ...state, search: payload };
        default:
            return state;
    }
}

function OrderProvider({ children }) {
    var [state, dispatch] = React.useReducer(orderReducer, initialData);

    return (
        <OrderStateContext.Provider value={state}>
            <OrderDispatchContext.Provider value={dispatch}>{children}</OrderDispatchContext.Provider>
        </OrderStateContext.Provider>
    );
}

function useOrderState() {
    var context = React.useContext(OrderStateContext);
    if (context === undefined) {
        throw new Error('useOrderState must be used within a OrderProvider');
    }
    return context;
}

function useOrderDispatch() {
    var context = React.useContext(OrderDispatchContext);
    if (context === undefined) {
        throw new Error('useOrderDispatch must be used within a OrderProvider');
    }
    return context;
}

function displayError(message, error) {
    console.log(error);
    const errorMsg = error.response?.data?.error || error.message;
    const finalMessage = errorMsg ? `${message}: ${errorMsg}` : message;
    toast.error(finalMessage);
}

// ###########################################################

const actions = {
    doResetState: () => async (dispatch) => {
        dispatch({ type: 'ORDER_RESET_STATE' });
    },

    doNewForm: () => async (dispatch) => {
        dispatch({ type: 'ORDER_FORM_RESET' });
    },

    doFind: (id) => async (dispatch) => {
        if (!config.isBackend) {
            dispatch({ type: 'ORDER_FORM_FIND_STARTED' });
            setTimeout(() => {
                dispatch({ type: 'ORDER_FORM_FIND_SUCCESS', payload: mockOrder });
            }, 1000);
        } else {
            try {
                dispatch({ type: 'ORDER_FORM_FIND_STARTED' });
                const order = await API.get(apiName, `/orders/${id}`);
                dispatch({ type: 'ORDER_FORM_FIND_SUCCESS', payload: order });
            } catch (error) {
                displayError('Unable to find the specified order', error);
                dispatch({ type: 'ORDER_FORM_FIND_ERROR' });
            }
        }
    },

    doCreate: (values) => async (dispatch) => {
        if (!config.isBackend) {
            dispatch({ type: 'ORDER_FORM_SAVE_SUCCESS' });
        } else {
            try {
                dispatch({ type: 'ORDER_FORM_SAVE_STARTED' });
                await API.post(apiName, '/orders', { body: values });
                dispatch({ type: 'ORDER_FORM_SAVE_SUCCESS' });
            } catch (error) {
                displayError('Unable to create order', error);
                dispatch({ type: 'ORDER_FORM_SAVE_ERROR' });
            }
        }
    },

    doCancel: (orderId, practiceId) => async (dispatch, history) => {
        if (!config.isBackend) {
            dispatch({ type: 'ORDER_FORM_SAVE_SUCCESS' });
        } else {
            try {
                dispatch({ type: 'ORDER_FORM_SAVE_STARTED' });
                await API.post(apiName, `/orders/cancel/${orderId}`, { body: { practiceId: practiceId } });
                dispatch({ type: 'ORDER_FORM_SAVE_SUCCESS' });
                history.push('/app/order/list');
                setTimeout(() => toast.success('Order is now cancelled'), 1000);
            } catch (error) {
                displayError('Unable to cancel order', error);
                dispatch({ type: 'ORDER_FORM_SAVE_ERROR' });
            }
        }
    },

    doUploadFile: (file, order) => async (dispatch) => {
        if (!file) {
            console.log('file to upload is undefined');
            dispatch({ type: 'ORDER_FORM_FILE_UPLOAD_ERROR' });
        } else if (!config.isBackend) {
            const res = { ...mockFileUpload };
            delete res.signed_link;
            dispatch({ type: 'ORDER_FORM_FILE_UPLOAD_SUCCESS', payload: { ...res, filename: file.name } });
        } else {
            try {
                dispatch({ type: 'ORDER_FORM_FILE_UPLOAD_STARTED' });
                const mustUploadToFullContour = order.method === scanMethod && order.provideTreatmentPlan !== 'Yes';
                const uploadLinkEndpoint = mustUploadToFullContour ? '/upload-link' : '/orders/upload-link';
                const fileExtension = extractExtensionFrom(file.name);
                const uniqueFilename = getUniqueFilename(order, fileExtension, mustUploadToFullContour);
                // get the upload link for the API
                const uploadLink = await API.post(apiName, uploadLinkEndpoint, {
                    headers: { 'Content-Type': 'application/json' },
                    body: { filename: uniqueFilename },
                });
                if (uploadLink.data.signed_link !== mockFileUpload.signed_link) {
                    // signed_link is not mocked, upload the file to S3 (FullContour or SmileAcademy)
                    await axios.put(uploadLink.data.signed_link, file, {
                        headers: { 'Content-Type': 'application/zip' },
                    });
                }
                delete uploadLink.data.signed_link;
                let payload = { ...uploadLink.data, filename: file.name };
                if (order.provideTreatmentPlan === 'Yes') payload.treatmentPlanFile = uniqueFilename;
                if (order.method === impressionMethod) payload.patientPhotos = uniqueFilename;
                dispatch({ type: 'ORDER_FORM_FILE_UPLOAD_SUCCESS', payload });
            } catch (error) {
                displayError('An error occurred trying to upload the file', error);
                dispatch({ type: 'ORDER_FORM_FILE_UPLOAD_ERROR' });
            }
        }
    },

    doDeleteUploadedFile: () => async (dispatch) => {
        dispatch({ type: 'ORDER_FORM_FILE_UPLOAD_DELETE' });
    },

    doFetch:
        (filter = null) =>
        async (dispatch) => {
            if (!config.isBackend) {
                dispatch({ type: 'ORDER_LIST_FETCH_STARTED' });
                setTimeout(() => {
                    dispatch({ type: 'ORDER_LIST_FETCH_SUCCESS', payload: mockOrdersList });
                }, 1000);
            } else {
                try {
                    dispatch({ type: 'ORDER_LIST_FETCH_STARTED' });
                    const data = await API.get(apiName, '/orders', { queryStringParameters: filter });
                    dispatch({ type: 'ORDER_LIST_FETCH_SUCCESS', payload: data });
                } catch (error) {
                    displayError('Unable to find orders', error);
                    dispatch({ type: 'ORDER_LIST_FETCH_ERROR' });
                }
            }
        },

    doDownloadTreatmentFile: async (practiceId, orderId, token) => {
        if (!practiceId || !orderId) return console.log('practiceId or orderId is undefined');
        if (!config.isBackend) return;
        try {
            const res = await API.post(apiName, '/orders/treatment-plan-link', {
                body: { practiceId, orderId, token },
            });
            if (!res.signed_link) throw new Error('Invalid download link');
            window.open(res.signed_link);
        } catch (error) {
            displayError('An error occurred trying to download the file', error);
        }
    },

    doSearchOrders: (search) => (dispatch) => {
        dispatch({ type: 'ORDER_LIST_SEARCH', payload: search });
    },
};

export { OrderProvider, useOrderState, useOrderDispatch, actions };
