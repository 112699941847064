import React from 'react';
import { LinearProgress, Box } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

// styles
import useStyles from '../styles';

// images
import photoRequirementsImage from '../../../images/photo-requirements.png';

// components
import { Typography } from '../../../components/Wrappers/Wrappers';
import Notification from '../../../components/Notification/Notification';

// context
import { useOrderDispatch, useOrderState, actions } from '../../../context/OrderContext';

// form attributes
import { impressionMethod } from '../helpers/form';

const AttachmentRequirements = ({ provideTreatmentPlan, isImpressionMethod }) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.marginText}>
                <span>Please upload in </span>
                <span className={classes.boldAndUnderlinedText}>one single zip file</span>
                <span> all the following files:</span>
            </Typography>

            {provideTreatmentPlan ? (
                <Typography
                    className={classes.marginText}
                    weight="bold"
                    style={provideTreatmentPlan ? { marginBottom: 15 } : { marginBottom: 0 }}
                >
                    - Upper and lower aches (STL files)
                </Typography>
            ) : isImpressionMethod ? (
                <>
                    <Typography className={classes.marginText} weight="bold">
                        - Patient's bite photos showing facial, left and right views
                    </Typography>
                    <Typography weight="bold">- Patient’s OPG (optional but highly recommended)</Typography>
                </>
            ) : (
                <>
                    <Typography className={classes.marginText} weight="bold">
                        - Upper, lower aches & bite (STL files)
                    </Typography>
                    <Typography className={classes.text} weight="bold">
                        - Bite photos showing facial, left and right views
                    </Typography>
                    <Typography className={classes.marginText} weight="bold">
                        - Patient’s OPG (optional but highly recommended)
                    </Typography>
                </>
            )}

            {!provideTreatmentPlan && (
                <div className={classes.photoReqWrap}>
                    <img src={photoRequirementsImage} alt="archive" height="100%" />
                </div>
            )}
        </>
    );
};

const FileUpload = ({ newOrder }) => {
    const classes = useStyles();
    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();

    const isUploadingFile = orderState.fileUploadLoading;
    const hasUploadedFile = orderState.fileUpload;

    const handleFiles = async (files) => {
        if (!files.length) return;
        await actions.doUploadFile(files[0], newOrder)(orderDispatch);
    };

    const deleteUploadedFile = () => actions.doDeleteUploadedFile()(orderDispatch);

    return (
        <>
            {!isUploadingFile && hasUploadedFile && (
                <Notification
                    className={classes.notificationItem}
                    type="shipped"
                    message="File uploaded successfully"
                    color="success"
                />
            )}

            {(isUploadingFile || hasUploadedFile) && isUploadingFile && (
                <Box mt={1} mr={2} width="100%">
                    <LinearProgress color="secondary" />
                    <Typography size={'sm'} color="secondary" style={{ marginTop: 10, marginBottom: 10 }}>
                        Uploading file
                    </Typography>
                </Box>
            )}

            <Box style={{ marginBottom: 35 }}>
                <DropzoneArea
                    acceptedFiles={['.zip']}
                    filesLimit={1}
                    maxFileSize={200000000} // 200Mb file size limit
                    dropzoneText={'Drag and drop zip file here or click to upload'}
                    showFileNames={true}
                    onChange={handleFiles}
                    onDelete={deleteUploadedFile}
                />
            </Box>
        </>
    );
};

const FileAttachment = ({ newOrder }) => {
    const provideTreatmentPlan = newOrder.provideTreatmentPlan === 'Yes';
    const isImpressionMethod = newOrder.method === impressionMethod;

    return (
        <>
            <AttachmentRequirements
                provideTreatmentPlan={provideTreatmentPlan}
                isImpressionMethod={isImpressionMethod}
            />
            <FileUpload newOrder={newOrder} />
        </>
    );
};

export default FileAttachment;
