import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    container: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        left: 0,
    },
    logotype: {
        display: 'flex',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logotypeText: {
        fontWeight: 500,
        color: 'white',
        marginLeft: theme.spacing(2),
    },
    logotypeIcon: {
        width: 300,
    },
    paperRoot: {
        boxShadow: theme.customShadows.widgetDark,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        maxWidth: 800,
    },
    textRow: {
        marginBottom: theme.spacing(4),
        textAlign: 'center',
    },
    textField: {
        marginBottom: theme.spacing(4),
        textAlign: 'center',
    },
    submitButton: {
        textTransform: 'none',
        fontSize: 18,
    },
}))
