import React from 'react'
import TextField from '@material-ui/core/TextField'

const CaseAttributes = ({ newOrder, formErrors, onTextFieldChange }) => (
    <>
        <TextField
            id="prescriberName"
            label="Prescriber Name"
            onChange={onTextFieldChange}
            name="prescriberName"
            value={newOrder.prescriberName || ''}
            variant="outlined"
            style={{ marginBottom: 35 }}
            helperText="Please enter the prescriber first name and last name"
            required
            error={formErrors.prescriberName}
        />

        <TextField
            id="patientName"
            label="Patient Name"
            onChange={onTextFieldChange}
            value={newOrder.patientName || ''}
            name="patientName"
            variant="outlined"
            style={{ marginBottom: 35 }}
            helperText="Please enter the patient first name and last name"
            required
            error={formErrors.patientName}
        />
    </>
)

export default CaseAttributes
