import React from 'react';
import {
    Home as HomeIcon,
    QuestionAnswer as SupportIcon,
    Description as DescriptionIcon,
    FormatListBulleted as FormatListBulletedIcon,
    ArrowDownward,
} from '@material-ui/icons';

const structure = [
    { id: 0, label: 'Dashboard', link: '/app/dashboard', icon: <HomeIcon /> },
    {
        id: 1,
        label: 'New Order',
        link: '/app/order/add',
        icon: <DescriptionIcon />,
    },
    {
        id: 2,
        label: 'My Orders',
        link: '/app/order/list?orderStatus=in-progress',
        icon: <FormatListBulletedIcon />,
    },
    { id: 3, type: 'divider' },
    { id: 4, type: 'title', label: 'HELP' },
    { id: 5, label: 'Contact Us', link: '/app/contact-us', icon: <SupportIcon /> },
    { id: 6, label: 'Download', link: '/app/download', icon: <ArrowDownward /> },
    // { id: 6, label: 'FAQ', link: '', icon: <FAQIcon /> },
    // { id: 7, label: 'About Us', link: '', icon: <PeopleIcon /> },
];

export default structure;
