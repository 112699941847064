import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    cardMedia: {
        margin: theme.spacing(4),
        width: '30rem',
    },
    cardMediaHidden: {
        margin: theme.spacing(4),
        width: '30rem',
        visibility: 'hidden',
    },
}));
