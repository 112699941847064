import React, { useState } from 'react'
import { Box, Grid, CircularProgress, Fade, TextField as Input } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

// styles
import 'react-toastify/dist/ReactToastify.css'
import useStyles from './styles'

// logo
import logo from '../../images/logo-large-dark.svg'

// context
import { useUserState, useUserDispatch, actions } from '../../context/UserContext'

//components
import { Button, Typography } from '../../components/Wrappers'
import Widget from '../../components/Widget'
import config from '../../config'

const getGreeting = () => {
    const d = new Date()
    if (d.getHours() >= 4 && d.getHours() <= 12) {
        return 'Good Morning'
    } else if (d.getHours() >= 13 && d.getHours() <= 16) {
        return 'Good Day'
    } else if (d.getHours() >= 17 && d.getHours() <= 23) {
        return 'Good Evening'
    } else {
        return 'Good Night'
    }
}

function Login(props) {
    const classes = useStyles()

    const userState = useUserState()
    const userDispatch = useUserDispatch()

    const [email, setEmail] = useState(config.isBackend ? '' : config.Auth.email)
    const [password, setPassword] = useState(config.isBackend ? '' : config.Auth.password)

    const isError = userState.errorMessage.length > 0

    const renderLogin = () => (
        <React.Fragment>
            {!config.isBackend ? (
                <Widget disableWidgetMenu inheritHeight style={{ marginTop: 32 }}>
                    <Typography variant={'body2'} block style={{ textAlign: 'center' }}>
                        This is a frontend app with no backend - use any credentials to login
                    </Typography>
                </Widget>
            ) : null}
            <Typography variant="h4" className={classes.formTitle}>
                LOGIN
            </Typography>
            <Typography variant="h1" className={classes.greeting}>
                {getGreeting()}
            </Typography>
            <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
            </div>
            <Fade in={isError} style={!isError ? { display: 'none' } : { display: 'inline-block' }}>
                <Typography color="secondary" className={classes.errorMessage}>
                    {userState.errorMessage}
                </Typography>
            </Fade>
            <Input
                id="email"
                InputProps={{
                    classes: {
                        underline: classes.InputUnderline,
                        input: classes.Input,
                    },
                }}
                value={email}
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
            />
            <Input
                id="password"
                InputProps={{
                    classes: {
                        underline: classes.InputUnderline,
                        input: classes.Input,
                    },
                }}
                value={password}
                onChange={e => setPassword(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
            />
            <div className={classes.formButtons}>
                {userState.isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                    <Button
                        disabled={email.length === 0 || password.length === 0}
                        onClick={() => actions.signIn(email, password, props.history)(userDispatch)}
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        Login
                    </Button>
                )}
            </div>
        </React.Fragment>
    )

    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={logo} alt="logo" className={classes.logotypeImage} />
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>{renderLogin()}</div>
                {config.isBackend && (
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={350} className={classes.generalInformation}>
                            <Typography color="quaternary">
                                <span>To create an account, please email your details to </span>
                                <a
                                    href={`mailto:${config.contactEmailAddress}`}
                                    className={classes.generalInformationLink}
                                >
                                    {config.contactEmailAddress}
                                </a>
                            </Typography>
                            <Typography color="quaternary" style={{ marginTop: 35 }}>
                                <span>Click </span>
                                <a
                                    href={config.youtubeVideo}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.youtubeLink}
                                >
                                    here
                                </a>
                                <span> to watch our portal tutorial</span>
                            </Typography>
                        </Box>
                    </Grid>
                )}
                <Typography color="primary" className={classes.copyright}>
                    © 2020 Smile Academy Pty Ltd. All rights reserved.
                </Typography>
            </div>
        </Grid>
    )
}

export default withRouter(Login)
