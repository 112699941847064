import React from 'react';
import { toast } from 'react-toastify';
import { mockUser } from './mock';
import { API, Auth } from 'aws-amplify';

import config from '../../src/config';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

const apiName = config.API.endpoints[0].name;

const isOrthoUser = (data) => {
    const userGroups = data?.signInUserSession?.idToken?.payload['cognito:groups'] || [];
    return RegExp('ortho', 'gi').test(userGroups);
};

// Use localStorage for isAuthenticated to avoid re-routing after a page refresh
const initialData = {
    isLoading: false,
    isAuthenticated: localStorage.getItem('isAuthenticated'),
    isOrtho: false,
    currentUser: null,
    territory: null,
    errorMessage: '',
};

function userReducer(state, { type, payload }) {
    switch (type) {
        case 'AUTH_STARTED':
            return { ...state, isLoading: true, errorMessage: '' };
        case 'LOGIN_SUCCESS':
            return { ...state, currentUser: payload, isLoading: false };
        case 'AUTH_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                currentUser: payload,
                isOrtho: isOrthoUser(payload),
                territory: payload.practice?.territory,
                isLoading: false,
            };
        case 'AUTH_ERROR':
            return { ...state, isAuthenticated: false, currentUser: null, isLoading: false, errorMessage: payload };
        case 'RESET_PASSWORD_STARTED':
            return { ...state, isLoading: true, errorMessage: '' };
        case 'RESET_PASSWORD_SUCCESS':
            return { ...state, isLoading: false, errorMessage: '' };
        case 'RESET_PASSWORD_ERROR':
            return { ...state, isLoading: false, errorMessage: payload };
        case 'SIGN_OUT_SUCCESS':
            return { ...initialData, isAuthenticated: localStorage.getItem('isAuthenticated') };
        default: {
            return state;
        }
    }
}

function UserProvider({ children }) {
    var [state, dispatch] = React.useReducer(userReducer, initialData);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    var context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
}

function useUserDispatch() {
    var context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }
    return context;
}

// ###########################################################

const actions = {
    signIn: (email, password, history) => async (dispatch) => {
        dispatch({ type: 'AUTH_STARTED' });
        if (!config.isBackend) {
            setTimeout(() => {
                localStorage.setItem('isAuthenticated', true);
                actions.getCurrentUser(history)(dispatch);
            }, 1000);
        } else {
            try {
                const user = await Auth.signIn(email, password);
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    dispatch({ type: 'LOGIN_SUCCESS', payload: user });
                    history.push('/password-reset');
                } else {
                    localStorage.setItem('isAuthenticated', true);
                    actions.getCurrentUser(history)(dispatch);
                }
            } catch (error) {
                console.log('Unable to login', error);
                dispatch({ type: 'AUTH_ERROR', payload: 'Something is wrong with your login or password' });
            }
        }
    },

    signOut: (history) => async (dispatch) => {
        if (config.isBackend) Auth.signOut();
        localStorage.removeItem('isAuthenticated');
        dispatch({ type: 'SIGN_OUT_SUCCESS' });
        history.push('/login');
    },

    getCurrentUser: (history) => async (dispatch) => {
        if (!config.isBackend) return dispatch({ type: 'AUTH_SUCCESS', payload: mockUser });
        try {
            const [cognitoUser, practice] = await Promise.all([
                Auth.currentAuthenticatedUser(),
                API.get(apiName, '/practice'),
            ]);
            dispatch({ type: 'AUTH_SUCCESS', payload: { ...cognitoUser, practice } });
        } catch (error) {
            console.log('Unable to get current user, signing out');
            actions.signOut(history)(dispatch);
        }
    },

    /**
     * Completes the user's new password
     * @param user the CognitoUser object
     * @param password the new password
     */
    completeNewPassword: (user, password, history) => async (dispatch) => {
        if (!config.isBackend || !user) return history.push('/login');
        try {
            dispatch({ type: 'RESET_PASSWORD_STARTED' });
            await Auth.completeNewPassword(user, password, []);

            dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
            toast.success('Your password has been updated');

            const email = user?.challengeParam?.userAttributes?.email;
            if (!email) return history.push('/login');

            await Auth.signIn(email, password);
            localStorage.setItem('isAuthenticated', true);
            actions.getCurrentUser(history)(dispatch);
        } catch (error) {
            console.log('Unable to change password', error);
            dispatch({ type: 'RESET_PASSWORD_ERROR', payload: error.message });
        }
    },
};

export { UserProvider, useUserState, useUserDispatch, actions };
