export const mockUser = {
    username: '76503934-53b3-48ce-b7f8-7da6ca537876',
    pool: {
        userPoolId: 'ap-southeast-2_abcdef',
        clientId: 'jkhde76edg76weg',
    },
    signInUserSession: {
        idToken: {
            jwtToken: 'an_id_token',
            payload: {
                sub: '76503934-53b3-48ce-b7f8-7da6ca537876',
                token_use: 'id',
                'cognito:groups': ['dental-practice'],
            },
        },
        refreshToken: {
            token: 'a_refresh_token',
        },
        accessToken: {
            jwtToken: 'a_jwt_token',
            payload: {
                sub: '76503934-53b3-48ce-b7f8-7da6ca537876',
                token_use: 'access',
                'cognito:groups': ['dental-practice'],
            },
        },
    },
    authenticationFlowType: 'USER_SRP_AUTH',
    attributes: {
        sub: '76503934-53b3-48ce-b7f8-7da6ca537876',
        email_verified: true,
        name: 'Carina Heights Dental',
        email: 'admin@smile-academy.com.au',
    },
    practice: {
        practiceId: '76503934-53b3-48ce-b7f8-7da6ca537876',
        name: 'Carina Heights Dental',
        address: '12 eather street, Coorparoo 4152 QLD',
        allowFullArchTreatment: true,
    },
};

export const mockOrder = {
    status: 'Needs Approval',
    createdAt: 1598924883029,
    orderId: 'db6e49a0-ec48-11ea-9c56-a3c6b024487e',
    prescriberName: 'Dr John Doe',
    patientName: 'Chris James',
    instructions: 'Instructions for the case',
    trimLine: 'Straight cut at the gingival zenith margin',
    allowIPR: 'Yes',
    allowAttachment: 'No',
    upperMidline: 'Move right',
    lowerMidline: 'Maintain',
    anteriorLeveling: 'Level By Incisal Edges',
    treatmentType: 'Treat 5 to 5',
    links: [
        {
            url: 'http://www.google.com.au',
            type: 'approval',
            token: '123',
        },
        {
            url: 'http://www.apple.com.au',
            type: 'patient',
            token: '456',
        },
    ],
};

export const mockFileUpload = {
    signed_link: 'http://mocked_presigned_url',
    key: 'mocked_filekey',
    original_filename: 'mocked_filename',
};

export const mockOrdersList = {
    count: 7,
    items: [
        {
            createdAt: 1598924883029,
            orderId: '2c427850-ebf5-11ea-91aa-31381756c164',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-114803',
            treatmentType: 'Treat 5 to 5',
            status: 'Waiting For Impression',
        },
        {
            createdAt: 1598402620082,
            orderId: '2f490920-e735-11ea-9477-5bbfd8711a3e',
            patientName: 'Emilien Perico',
            prescriberName: 'Dr John Doe',
            treatmentType: 'Treat 5 to 5',
            serialNumber: '200902-101204',
            status: 'Case Submitted',
        },
        {
            createdAt: 1598959884704,
            orderId: 'aae23a00-ec46-11ea-a63e-b5661439f3a8',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-213124',
            treatmentType: 'Treat 5 to 5',
            status: 'Case Needs Approval',
        },
        {
            createdAt: 1598923863133,
            orderId: 'cc5ab0d0-ebf2-11ea-8c4c-67334c09cf5a',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-113103',
            treatmentType: 'Treat 5 to 5',
            status: 'Case Redesign',
        },
        {
            createdAt: 1598960825146,
            orderId: 'db6e49a0-ec48-11ea-9c56-a3c6b024487e',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-214705',
            treatmentType: 'Treat 5 to 5',
            status: 'Case Approved',
        },
        {
            createdAt: 1598924326573,
            orderId: 'e09605d0-ebf3-11ea-900d-99cf845304ad',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-113846',
            treatmentType: 'Treat 5 to 5',
            status: 'Case In Production',
        },
        {
            createdAt: 1598924326593,
            orderId: 'e09605d0-ebf3-11ea-900d-99cf845304ad',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-113846',
            treatmentType: 'Treat 5 to 5',
            status: 'Case Shipped',
        },
        {
            createdAt: 1598944326573,
            orderId: 'e09605d0-ebf3-11ea-900d-99cf845304gf',
            patientName: 'Pete Simmons',
            prescriberName: 'Dr Alison James',
            serialNumber: '200901-113823',
            treatmentType: 'Treat 5 to 5',
            status: 'Case In Progress',
        },
    ],
};
