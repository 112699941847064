import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// styles
import useStyles from '../styles';

// components
import { Typography } from '../../../components/Wrappers/Wrappers';

// form attributes
import { impressionMethod, scanMethod } from '../helpers/form';

const CaseMethod = ({ newOrder, methodClicked }) => {
    const classes = useStyles();

    const Scan = () => (
        <Card
            className={newOrder.method === scanMethod ? classes.methodCardClicked : classes.methodCard}
            onClick={methodClicked(scanMethod)}
        >
            <CardContent>
                <Typography variant="h6" weight="bold" style={{ marginBottom: 15 }}>
                    3D Scan
                </Typography>

                <Typography>I have an intraoral 3D scanner and want to upload my patient files</Typography>
            </CardContent>
        </Card>
    );

    const Impression = () => (
        <Card
            className={newOrder.method === impressionMethod ? classes.methodCardClicked : classes.methodCard}
            onClick={methodClicked(impressionMethod)}
        >
            <CardContent>
                <Typography variant="h6" weight="bold" style={{ marginBottom: 15 }}>
                    Traditional impression
                </Typography>

                <Typography>I have a traditional impression that I will send to Smile Academy PO Box</Typography>

                <Typography className={classes.noteText} style={{ marginTop: 10 }}>
                    Notes
                </Typography>

                <Typography className={classes.noteText}>
                    - PVS and silicone impressions are better than alginate
                </Typography>
            </CardContent>
        </Card>
    );

    return (
        <>
            <Scan />
            <Impression />
        </>
    );
};

export default CaseMethod;
