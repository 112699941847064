import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import { Close as CloseIcon } from '@material-ui/icons'
import useStyles from './styles'

// components
import Layout from './Layout'

// pages
import Error from '../pages/error'
import Login from '../pages/login'
import Reset from '../pages/reset'
import TreatmentPlan from '../pages/treatment-plan'

// context
import { useUserState } from '../context/UserContext'

const App = () => {
    const classes = useStyles()
    const userState = useUserState()

    const isAuth = userState.isAuthenticated

    const CloseButton = ({ closeToast, className }) => <CloseIcon className={className} onClick={closeToast} />

    const PrivateRoute = ({ component, ...rest }) => (
        <Route
            {...rest}
            render={props => (isAuth ? React.createElement(component, props) : <Redirect to={'/login'} />)}
        />
    )

    const PublicRoute = ({ component, ...rest }) => (
        <Route
            {...rest}
            render={props => (isAuth ? <Redirect to={{ pathname: '/' }} /> : React.createElement(component, props))}
        />
    )

    return (
        <>
            <ToastContainer
                className={classes.toastsContainer}
                position="top-right"
                autoClose={3000}
                transition={Slide}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                closeButton={<CloseButton className={classes.notificationCloseButton} />}
            />
            <HashRouter>
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
                    <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
                    <PrivateRoute path="/app" component={Layout} />
                    <PublicRoute path="/login" component={Login} />
                    <PublicRoute path="/password-reset" exact component={Reset} />
                    <PublicRoute path="/treatment-plan" component={TreatmentPlan} />
                    <Route component={Error} />
                </Switch>
            </HashRouter>
        </>
    )
}

export default App
