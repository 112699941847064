import React, { useEffect } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';

// pages
import Dashboard from '../../pages/dashboard';
import OrderAdd from '../../pages/order/AddOrder';
import OrderRefine from '../../pages/order/RefineOrder';
import OrderView from '../../pages/order/ViewOrder';
import OrderList from '../../pages/order/OrderList';
import ContactUs from '../../pages/contact/ContactUs';
import Download from '../../pages/download/Download';
import BreadCrumbs from '../../components/BreadCrumbs';

// context
import { useLayoutState } from '../../context/LayoutContext';
import { useUserState, useUserDispatch, actions } from '../../context/UserContext';

//Sidebar structure
import structure from '../Sidebar/SidebarStructure';

function Layout(props) {
    const classes = useStyles();
    const layoutState = useLayoutState();
    const userState = useUserState();
    const userDispatch = useUserDispatch();

    useEffect(() => {
        if (!userState.currentUser) {
            actions.getCurrentUser(props.history)(userDispatch);
        }
    }, []); // eslint-disable-line

    return (
        <div className={classes.root}>
            <Header history={props.history} />
            <Sidebar structure={structure} />
            <div
                className={classnames(classes.content, {
                    [classes.contentShift]: layoutState.isSidebarOpened,
                })}
            >
                <div className={classes.fakeToolbar} />
                <BreadCrumbs />
                <Switch>
                    <Route path="/app/dashboard" component={Dashboard} />
                    <Route exact path="/app/order" render={() => <Redirect to="/app/order/list" />} />
                    <Route path="/app/order/add" component={OrderAdd} />
                    <Route path="/app/order/view" component={OrderView} />
                    <Route path="/app/order/refine" component={OrderRefine} />
                    <Route path="/app/order/list" component={OrderList} />
                    <Route path="/app/contact-us" component={ContactUs} />
                    <Route path="/app/download" component={Download} />
                </Switch>
            </div>
        </div>
    );
}

export default withRouter(Layout);
