import React from 'react'
import { Box, Grid } from '@material-ui/core'

// styles
import useStyles from './styles'

// images
import logo from '../../images/logo-large.jpg'

// components
import { Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

// config
import config from '../../config'

const ContactUs = () => {
    const classes = useStyles()
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={600}>
                            <Typography variant="h5" weight="medium" style={{ marginBottom: 50 }}>
                                Don't hesitate to contact us for any enquiries
                            </Typography>
                            <Grid container direction="column" spacing={3}>
                                <Grid item container alignItems="center" key="phone">
                                    <Grid item xs={6}>
                                        <Typography variant="body1">Phone</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.text} weight="bold">
                                            07 3547 3970
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center" key="email">
                                    <Grid item xs={6}>
                                        <Typography variant="body1">Email</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.text} weight="bold">
                                            <a href={`mailto:${config.contactEmailAddress}`}>
                                                {config.contactEmailAddress}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="top" key="address">
                                    <Grid item xs={6}>
                                        <Typography variant="body1">Delivery address</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.text} weight="bold">
                                            Smile Academy
                                        </Typography>
                                        <Typography className={classes.text} weight="bold">
                                            PO Box 355, Lutwyche
                                        </Typography>
                                        <Typography className={classes.text} weight="bold">
                                            QLD 4030 Australia
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center" key="logo">
                                    <img
                                        src={logo}
                                        width="300px"
                                        alt="logo"
                                        className={classes.logotypeImage}
                                        style={{ marginLeft: '4rem' }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default ContactUs
